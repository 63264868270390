:root {
  // Colors
  --colorPrimary: #00ddee;
  --colorPrimaryInactive: rgba(0, 221, 238, 0.5);
  --colorPrimaryDark: #101b2d;
  --colorPrimaryDarkInactive: rgba(26, 32, 60, 0.8);
  --colorWhite: #fff;
  --colorWhiteActive: rgba(255, 255, 255, 0.87);
  --colorWhiteInactive: rgba(255, 255, 255, 0.6);
  --colorWhiteDisabled: rgba(255, 255, 255, 0.38);
  --colorWhiteTransparent: rgba(255, 255, 255, 0.12);
  --colorSurface: rgb(18, 18, 18);
  --colorBlack: #000000;
  --colorSurfaceInactive: rgba(59, 31, 31, 0.87);
  --colorSurfaceDisabled: rgba(18, 18, 18, 0.6);
  --colorMainPageBg: linear-gradient(180deg, #3d004c 0%, #08000a 100%);
  --colorBannerBg: #00cce7;
  --colorBannerShadow: 0px 0px 14px rgba(0, 221, 238, 0.35);
  --colorBurnMenuShadow: 0px -5px 20px rgba(0, 146, 159, 0.5);
  --colorWarningShadow: 0px 0px 10px rgba(255, 79, 36, 0.5);
  --colorWhiteDelimeter: rgba(255, 255, 255, 0.12);
  --colorBackDrop: rgba(0, 0, 0, 0.4);
  --colorRed: rgb(255, 51, 68);
  --colorRedDisabled: rgba(255, 51, 68, 0.6);
  --colorWarning: #ff4f24;
  --colorWarningInactive: rgba(80, 34, 34, 0.87);
  --colorWarningDisabled: rgba(255, 79, 36, 0.38);
  --colorBurnSuccessBackground: rgb(216, 81, 64);
  --elevationOverlay04dp: #121926;
  --colorGreen: rgb(0, 187, 0);
  --colorGreenDisabled: rgba(0, 187, 0, 0.6);
  --colorGreenShadow: 0px 0px 10px rgba(0, 187, 0, 0.5);
  --colorCheckboxDisabled: #00929f;
  --colorContextMenu: linear-gradient(0deg, rgba(0, 102, 255, 0.12), rgba(0, 102, 255, 0.12)),
    #121212;
  --sab: env(safe-area-inset-bottom);

  // Fonts
  --baseFont: 'Exo 2', sans-serif;
}
