@import '../../assets/styles/base/medias';

.recipe-output {
  &__box {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
    }

    &__preview {
      width: 40%;
      box-sizing: border-box;
      padding: 10px 0;
      border-radius: 10px;
      @include mobile {
        width: 100%;
        padding-top: 0;
      }

      &-img {
        margin-top: 1rem;
        box-sizing: border-box;
        padding: 15px;
        border-radius: 10px;
        background-color: var(--colorPrimaryDark);
        @include desktop {
          position: sticky;
          top: 80px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__available {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    font-size: 14px;
    &--active {
      color: var(--colorPrimary) !important;
      opacity: 1 !important;
    }
    button {
      padding: 0;
      border: none;
      appearance: none;
      color: var(--colorPrimary);
      background: transparent;
      opacity: 0.7;
      margin: 0 5px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__input-container {
    margin-top: 15px
  }
}