.input-wrapper {
  position: relative;
  border: 1px solid var(--colorSurfaceDisabled);
  border-radius: 0px 8px 0px 8px;
  clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 6px);
  box-shadow: var(--colorBannerShadow);

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 2px;
    width: 1px;
    height: 8px;
    background-color: var(--colorSurface);
    transform: rotate(45deg);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    right: 2px;
    width: 1px;
    height: 8px;
    background-color: var(--colorSurface);
    transform: rotate(45deg);
  }
  &--light {
    border: 1px solid var(--colorWhiteInactive);
    &:before {
      background-color: var(--colorWhiteInactive);
    }
    &:after {
      background-color: var(--colorWhiteInactive);
    }
  }
}

.input {
  width: 100%;
  padding: 16px;
  font-size: 1.8em;
  line-height: 1.2;
  font-weight: 600;
  font-family: var(--baseFont);
  color: var(--colorSurface);
  border: none;
  background-color: transparent;
  outline: none;
  transition: 0.3s color ease;

  &::placeholder {
    line-height: 1.2;
    font-weight: 600;
    font-family: var(--baseFont);
    color: var(--colorSurfaceDisabled);
    transition: 0.3s color ease;
  }

  &:focus {
    color: var(--colorSurface);
  }
  &--light {
    color: var(--colorWhiteDisabled);
    font-weight: 500;
    &::placeholder {
      line-height: 1.2;
      font-weight: 600;
      font-family: var(--baseFont);
      color: var(--colorWhiteDisabled);
      transition: 0.3s color ease;
    }
    .input__label {
      color: var(--colorWhite);
    }
    &:focus {
      color: var(--colorWhiteDisabled);
    }
  }
}

.input_error {
  color: var(--colorRed);
}

.input__label {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 1.8em;
  font-weight: 600;
  color: var(--colorSurfaceDisabled);
}
