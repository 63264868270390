@import './base/variables';

@font-face {
  font-family: 'Exo 2';
  src: local('Exo 2 Regular'), url(../fonts/Exo2-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2';
  font-weight: 500;
  src: local('Exo 2 Medium'), url(../fonts/Exo2-Medium.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2';
  font-weight: 600;
  src: local('Exo 2 Bold'), url(../fonts/Exo2-Bold.ttf) format('truetype');
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100vh;
  overflow: auto;
  font-family: var(--baseFont);
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  font-size: 10px;
  color: var(--colorWhiteActive);
  background-color: var(--colorSurface);
}

body.no-scroll {
  overflow-y: hidden;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

#root {
  scroll-behavior: smooth;
  padding-bottom: 100px;
}

.container {
  max-width: 1170px;
  padding: 15px;
  margin: 0 auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
}
