@mixin borders($cornerWidth) {
  clip-path: polygon(
    calc(#{$cornerWidth}) 0,
    100% 0,
    100% calc(100% - #{$cornerWidth}),
    calc(100% - #{$cornerWidth}) 100%,
    0 100%,
    0 #{$cornerWidth}
  );
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
}
