@import '../../assets/styles/base/medias';

.new-template {
  box-sizing: border-box;
  padding: 15px;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;

  &__clear-btn {
    font-size: 10px;
    width: 110px;
    height: 22px;
    padding: 14px;
  }

  &--active {
    border: 1px solid var(--colorPrimary) !important;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
    }
    code {
      word-break: break-word;
    }
    &-item {
      display: flex;
      align-items: center;
      color: var(--colorWhite);
      margin-bottom: 3px;
      img {
        height: 30px;
        width: 30px;
        object-fit: contain;
        margin-right: 15px;
      }
    }
  }

  &__search {
    margin-top: 2rem;
    width: 400px;
    input::placeholder {
      color: rgb(49, 33, 33);
    }
  }

  &__actions {
    position: absolute;
    right: 0;
    top: 60px;
    display: grid;
    grid-template-columns: 130px 130px 140px;
    grid-gap: 20px;
    align-items: center;
    a {
      text-decoration: none;
    }
    button {
      padding: 10px 5px;
      font-size: 0.8rem;
      text-decoration: none;
      &:hover {
        a {
          color: var(--colorPrimary);
        }
      }
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }

  &__name {
    color: var(--colorPrimary);
  }

  &--active {
    border: 1px solid var(--colorPrimary) !important;
  }

  &__back {
    margin: 0.5rem 0;
    margin-bottom: 1.2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: var(--colorPrimary);
      svg,
      img {
        width: 22px;
        margin-right: 10px;
        transform: rotate(180deg);
      }
    }
  }
}
