$gradient: linear-gradient(180deg, #3d004c 0%, #08000a 100%);
$colorPrimary: #00ddee;
.notFound {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: $gradient;

  &__title {
    font-size: 16vh;
    line-height: 16vh;
    color: $colorPrimary;
    margin: 20px 0;
    opacity: 0.8;
    position: relative;
    z-index: 2;
    margin-top: -2vh;
  }

  &__subtitle {
    font-size: 3vh;
    color: $colorPrimary;
    text-transform: uppercase;
    margin: 10px 0;
    opacity: 1;
    position: relative;
    z-index: 2;
  }

  &__nosupport {
    font-size: 1.5vh;
    color: $colorPrimary;
    text-transform: uppercase;
    margin: 10px 0;
    opacity: 0.8;
    position: relative;
    z-index: 2;
  }

  &__logo {
    position: absolute;
    top: 15%;
    z-index: 0;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 30%;
    opacity: 0.25;
  }

  &__button-container {
    text-align: center;
    width: 50vw;
    margin: 0 auto;
    margin-top: 2vh;
    max-width: 200px;
  }
}
