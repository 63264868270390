@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes blink {
  17% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  83% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
