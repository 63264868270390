@import 'assets/styles/base/_medias';

.header {
  position: sticky;
  top: 0;
  z-index: 3;
  box-shadow: var(--colorWhiteTransparent) 0 1px 0, var(--colorWhiteTransparent) 0 0 8px;
  background-color: var(--colorPrimaryDark);
  &__wrapper {
    max-width: 1100px;
    box-sizing: border-box;
    padding: 7px 5px;
    margin: 0 auto;
    font-size: 1.8em;
    font-weight: 500;
    color: var(--colorPrimary);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      padding: 7px 15px;
      font-size: 1.5em;
    }
  }

  &__network {
    font-size: 12px;
    div {
      margin-top: 5px;
    }
    span {
      box-sizing: border-box;
      padding: 3px 10px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}
