@import '../../assets/styles/base/medias';

.recipe-confirmation {
  &__output-container {
    width: 40%;
    margin-right: 40px;
    min-width: 40%;

    @include mobile {
      width: 33%;
      margin-right: 30px;
      min-width: 33%;
      img {
        position: sticky;
        top: 30px;
      }
    }
  }

  &__input-container {
    flex-grow: 1;
    @include mobile {
      width: 62%;
    }

    code {
      word-break: break-word;
    }

    ul {
      padding-left: 0;
    }

    &-item {
      display: flex;
      align-items: center;
      color: var(--colorWhite);
      margin-bottom: 3px;
      img {
        height: 30px;
        width: 30px;
        object-fit: contain;
        margin-right: 15px;
      }
    }
  }
}