@import 'assets/styles/base/_medias';
.collection-card {
  display: flex;
  box-sizing: border-box;
  padding: 15px;
  background-color: var(--colorPrimaryDark);
  border: 1px solid transparent;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  min-height: 195px;

  @include mobile {
    min-height: 150px;
  }
  &:hover {
    border: 1px solid var(--colorPrimary);
    cursor: pointer;
  }
  &__name {
    text-decoration: none;
    font-size: 1.6rem;
    color: var(--colorPrimary);
    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__count {
    display: block;
    margin-top: 1rem;
    font-size: 1rem;
    opacity: 0.4;
    @include mobile {
      font-size: 0.9rem;
    }
  }
  &__logo {
    width: 130px;
    min-width: 130px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
