@import '../../assets/styles/base/medias';

.recipe-input {
  &__available {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    font-size: 14px;
    &--active {
      color: var(--colorPrimary) !important;
      opacity: 1 !important;
    }
    button {
      padding: 0;
      border: none;
      appearance: none;
      color: var(--colorPrimary);
      background: transparent;
      opacity: 0.7;
      margin: 0 5px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__list {
    margin-top: 4em;

    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-gap: 20px;
    @include mobile {
      margin-top: 2em;
      grid-template-columns: 1fr 1fr;
    }
  }

  &--active {
    border: 1px solid var(--colorPrimary) !important;
  }

  &__box-inputs {
    width: 58%;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 10px;
    margin-top: -4px;
    @include mobile {
      width: 100%;
      padding: 5px 0;
    }
    &__actions {
      display: grid;
      grid-template-columns: 370px 100px 100px;
      grid-gap: 20px;
      height: 55px;
      align-items: flex-start;
      @include mobile {
        grid-template-columns: 5fr 1fr;
        grid-gap: 5px;
        height: auto;
        button {
          height: 40px !important;
        }
      }
      button {
        height: 55px;
      }
      input {
        height: 53px;
      }
      button {
        font-size: 14px;
      }
    }
  }
}
