@import '../../assets/styles/base/medias';

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 18px;
  text-align: center;
  font-size: 1.6em;
  text-transform: uppercase;
  color: var(--colorSurfaceInactive);
  border: 1px solid var(--colorPrimary);
  background-color: var(--colorPrimary);
  box-shadow: 0px 0px 5px var(--colorPrimaryInactive);
  border-radius: 0px 8px 0px 8px;
  clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 6px);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &__icon {
    margin-right: 8px;
  }

  &:not([disabled]):hover {
    @include desktop {
      color: var(--colorPrimary);
      background-color: transparent;
    }
  }

  &:not([disabled]):active {
    color: var(--colorPrimary);
    background-color: transparent;
  }

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 2px;
    width: 1px;
    height: 8px;
    background-color: var(--colorPrimary);
    transform: rotate(45deg);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    right: 2px;
    width: 1px;
    height: 8px;
    background-color: var(--colorPrimary);
    transform: rotate(45deg);
  }
}

.btn[disabled] {
  opacity: 0.38;
  cursor: default;
}

.btn.btn_black {
  color: var(--colorWhite);
  border-color: var(--colorSurface);
  background-color: var(--colorSurface);

  &::before {
    background-color: var(--colorSurface);
  }

  &::after {
    background-color: var(--colorSurface);
  }

  &:not([disabled]):hover {
    @include desktop {
      color: var(--colorSurface);
      background-color: transparent;
    }
  }

  &:not([disabled]):active {
    color: var(--colorSurface);
    background-color: transparent;
  }
}

.btn.btn_dark {
  color: var(--colorPrimary);
  border-color: var(--colorPrimary);
  background-color: var(--colorSurface);
  padding: 13px;
  font-size: 12px;

  &.btn_warning {
    border-color: var(--colorPrimary);
    background-color: var(--colorPrimary);
    color: var(--colorSurface);

    &:not([disabled]):active {
      color: var(--colorPrimary);
      background-color: transparent;
    }

    &::before {
      background-color: var(--colorPrimary);
    }

    &::after {
      background-color: var(--colorPrimary);
    }
  }
}

.btn.btn_warning {
  background-color: var(--colorWarning);
  border-color: var(--colorWarning);
  color: var(--colorWhite);
  box-shadow: var(--colorWarningShadow);

  &::before {
    background-color: var(--colorWarning);
  }

  &::after {
    background-color: var(--colorWarning);
  }

  &:not([disabled]):hover {
    @include desktop {
      color: var(--colorPrimary);
      background-color: transparent;
    }
  }

  &:not([disabled]):active {
    color: var(--colorWarning);
    background-color: transparent;
  }
}

.btn.btn_success {
  background-color: var(--colorGreen);
  border-color: var(--colorGreen);
  color: var(--colorWhite);
  box-shadow: var(--colorGreenShadow);

  &::before {
    background-color: var(--colorGreen);
  }

  &::after {
    background-color: var(--colorGreen);
  }

  &:not([disabled]):hover {
    @include desktop {
      color: var(--colorGreen);
      background-color: transparent;
    }
  }

  &:not([disabled]):active {
    color: var(--colorGreen);
    background-color: transparent;
  }
}

.btn.btn_bordered {
  color: var(--colorPrimary);
  background-color: transparent;

  &:not([disabled]):hover {
    @include desktop {
      color: var(--colorSurfaceInactive);
      background-color: var(--colorPrimary);
    }
  }

  &:not([disabled]):active {
    color: var(--colorSurfaceInactive);
    background-color: var(--colorPrimary);
  }
}

.btn.btn_bordered.btn_black {
  color: var(--colorSurface);
  background-color: transparent;

  &:not([disabled]):hover {
    @include desktop {
      color: var(--colorWhite);
      background-color: var(--colorSurface);
    }
  }

  &:not([disabled]):active {
    color: var(--colorWhite);
    background-color: var(--colorSurface);
  }
}

.btn.btn_bordered.btn_warning {
  color: var(--colorWarning);
  background-color: transparent;

  &:not([disabled]):hover {
    @include desktop {
      color: var(--colorWhite);
      background-color: var(--colorWarning);
    }
  }

  &:not([disabled]):active {
    color: var(--colorWhite);
    background-color: var(--colorWarning);
  }
}

.btn.btn_bordered.btn_success {
  color: var(--colorGreen);
  background-color: transparent;

  &:not([disabled]):hover {
    @include desktop {
      color: var(--colorWhite);
      background-color: var(--colorGreen);
    }
  }

  &:not([disabled]):active {
    color: var(--colorWhite);
    background-color: var(--colorGreen);
  }
}

.btn__disabled-click-wrapper {
  position: relative;
}

.btn__disabled-click {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  background-color: transparent;
  z-index: 2;
}
