@import '../../assets/styles/base/_variables.scss';
@import '../../assets/styles/base/_mixins.scss';
@import '../../assets/styles/base/medias';

.ui-popup-backdrop {
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  background: rgba(255, 0, 0, 0.2);
  z-index: 2;
  max-height: 100vh;
  overflow-y: auto;
  @include mobile {
    max-height: 100vh;
    overflow-y: auto;
  }
}

.ui-popup {
  box-sizing: border-box;
  position: static;
  top: 150px;
  margin: 150px auto;
  max-width: 480px;
  width: calc(100% - 40px);
  background-color: var(--elevationOverlay04dp);
  z-index: 3;
  left: 50%;

  padding: 17px 24px 32px 17px;
  color: var(--colorWhiteInactive);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  @include borders(6px);

  @include mobile {
    position: static;
    margin: 20px auto;
    left: initial;
    transform: initial;
  }

  article {
    font-size: 14px;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    @include mobile {
      font-size: 12px;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    button {
      font-size: 14px;
      padding: 10px 15px;
      width: 150px;
    }
  }
  h2 {
    margin: 0;
    color: var(--colorWhiteActive);
  }
  a {
    color: var(--colorPrimary);
  }
}
