@import '../../assets/styles/base/_mixins.scss';

$cornerWidth: 6px;

.ui-select {
  height: 55px;
  clip-path: polygon(
    calc(#{$cornerWidth}) 0,
    100% 0,
    100% calc(100% - #{$cornerWidth}),
    calc(100% - #{$cornerWidth}) 100%,
    0 100%,
    0 #{$cornerWidth}
  );
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 4px solid #a0a0a0;
  position: relative;
  color: var(--colorWhite);

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    @include borders($cornerWidth);
    background-color: var(--colorSurface);
    transition: box-shadow 0.2s;
  }
  &__white-logo {
    background: #fafafa;
  }

  &--open {
    border: 4px solid var(--colorPrimary);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    clip-path: polygon(
      calc(#{$cornerWidth}) 0,
      100% 0,
      100% 100%,
      calc(100% - #{$cornerWidth}) 100%,
      0 100%,
      0 #{$cornerWidth}
    );

    &::after {
      background: #101c2e;
      height: calc(100% + 15px);
      top: -3px;
      clip-path: polygon(
        calc(#{$cornerWidth}) 0,
        100% 0,
        100% 0,
        100% 100%,
        0 100%,
        0 #{$cornerWidth}
      );
    }
  }

  &__search {
    width: 100%;
    height: 33px !important;
    border: 1px solid #a0a0a0;
    background-color: var(--colorSurface);
    color: var(--colorWhiteInactive);
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: 0.015em;
    font-family: 'Exo 2', Arial, Helvetica, sans-serif;
    font-weight: 500;
    outline: none;
  }

  &__selected {
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 12px;

    &__image {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }

    &__text {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      width: 100%;

      &__amount {
        letter-spacing: 0.015em;
        color: var(--colorWhite);
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        float: right;
        margin-right: 25px;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    background: #121212;
    color: #fff;
    z-index: 3;
    box-sizing: border-box;
    padding: 2px 0 0 12px;
    border: 4px solid var(--colorPrimary);
    border-top: none;
    clip-path: polygon(
      20% 0%,
      80% 0%,
      100% 0,
      100% calc(100% - #{$cornerWidth}),
      calc(100% - #{$cornerWidth}) 100%,
      20% 100%,
      0 100%,
      0 0
    );
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;

    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      top: -7px;
      left: -3px;
      width: calc(100% + 6px);
      height: calc(100% + 10px);
      @include borders($cornerWidth);
      background-color: var(--colorBlack);
      transition: box-shadow 0.2s;
    }

    &__search {
      padding: 10px 0 5px 0;
      position: sticky;
      top: 0;
      background-color: var(--colorBlack);
    }

    &__wrapper {
      max-height: 242px;
      overflow-y: auto;
      box-sizing: border-box;
      padding-right: 12px;
    }

    &__option {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;

      &__image {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }

      &__text {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.015em;
        border-bottom: 1px solid var(--colorWhiteDelimeter);
        text-transform: uppercase;
        flex-grow: 1;
        padding: 13px 0;
      }

      &:last-child &__text {
        border-bottom: none;
      }
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;

    &::after {
      content: '';
      z-index: 2;
      position: absolute;
      pointer-events: none;
      display: block;
      width: 24px;
      height: 24px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('./images/arrow.svg');
      background-size: 24px;
      background-position: center;
      right: 5px;
    }

    &--open {
      &::before {
        content: '';
        z-index: -1;
        position: absolute;
        z-index: 4;
        top: 55px;
        left: 1px;
        right: 0;
        width: calc(100% - 2px);
        height: 1px;
        background: var(--colorWhiteDelimeter);
      }
    }
  }
}
