.notify {
  position: fixed;
  top: 70px;
  right: 20px;
  width: 300px;
  z-index: 3;

  &-item {
    position: relative;
    box-sizing: border-box;
    padding: 30px 15px;
    color: var(--colorWhite);
    border-radius: 12px;
    margin-bottom: 15px;
    font-size: 16px;
    overflow: hidden;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
    &--success {
      background: rgb(51, 184, 100, 0.55);
      backdrop-filter: blur(3px);
    }
    &--error {
      background: rgba(211, 61, 61, 0.55);
      backdrop-filter: blur(3px);
    }
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 100%;
      background: var(--colorWhite);

      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      animation: float 4s forwards;
    }
  }
}

@keyframes float {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
