@import 'assets/styles/base/_medias';

.main-view {
  max-width: 1100px;
  box-sizing: border-box;
  padding: 15px;
  margin: 0 auto;

  &__collection-list {
    margin-top: 2em;

    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 20px;

    @include mobile {
      grid-template-columns: 100%;
    }
  }
}
