@import '../../assets/styles/base/medias';
@import '../../assets/styles/base/animations';

.template-card {
  display: flex;
  flex-direction: column;
  height: 240px;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  background-color: var(--colorPrimaryDark);
  transition: 0.3s border ease;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid transparent;

  @include desktop {
    border: 1px solid transparent;
    min-height: 240px;

    &:hover {
      border: 1px solid var(--colorPrimary);
    }
  }

  &_extra-layer {
    position: relative;
    box-shadow: inset -4px 4px 0px var(--colorSurface), 3px -5px 0px var(--colorPrimaryDark);
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    width: 100%;

    &-name {
      width: 100%;
      font-size: 1.4em;
      color: var(--colorWhiteActive);
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-direction: column;

      &--bold {
        font-size: 0.8rem;
        opacity: 0.7;
      }

      @include desktop {
        font-size: 1.4em;
      }
    }

    &-number {
      font-size: 1.4em;
      color: var(--colorWhiteInactive);
    }
  }

  &__media {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 8px;
    overflow: hidden;

    &-img {
      animation-duration: 1000ms;
      animation-name: fadeIn;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
